.app-menu {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .app-menu .apps-base {
    margin-left: 30%;
    color: #3C414B;
    font-size: 15px;
    font-weight: 600;
    width: 140px; }
  .app-menu .menu-close {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #3C414B;
    margin-left: 21%;
    text-align: center;
    cursor: pointer;
    transition: transform 200ms ease; }

.menu-placeholder {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  margin: auto; }

.app-menu .menu-close:hover {
  background-color: #3C414B;
  color: white;
  transform: rotate(-90deg);
  transition: transform 200ms ease; }
