.building-stats-parent {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100vh;
  background: white;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 30; }
  .building-stats-parent.hide {
    left: -410px; }
  .building-stats-parent.classroom .svg-dot circle {
    fill: #3ea0b7; }
  .building-stats-parent.classroom button.building-data-button {
    background: #3ea0b7; }
  .building-stats-parent.lab .svg-dot circle {
    fill: #2a575f; }
  .building-stats-parent.lab button.building-data-button {
    background: #2a575f; }
  .building-stats-parent.housing .svg-dot circle {
    fill: #3778BC; }
  .building-stats-parent.housing button.building-data-button {
    background: #3778BC; }
  .building-stats-parent.community .svg-dot circle {
    fill: #107d4b; }
  .building-stats-parent.community button.building-data-button {
    background: #107d4b; }
  .building-stats-parent.office .svg-dot circle {
    fill: #93bb45; }
  .building-stats-parent.office button.building-data-button {
    background: #93bb45; }

.graph-hover-wrapper {
  position: absolute;
  z-index: 50;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: row; }
  .graph-hover-wrapper .graph-hover-tip-container {
    width: 20px;
    height: 150px;
    overflow: hidden;
    pointer-events: none;
    position: relative; }
    .graph-hover-wrapper .graph-hover-tip-container .graph-hover-tip {
      width: 17px;
      height: 17px;
      padding: 1px;
      margin: -10px auto 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 50%;
      background: white;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
      border: solid 1px #e8e8e8; }
  .graph-hover-wrapper .graph-hover-content {
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
    background: white;
    height: 160px;
    width: 180px;
    box-sizing: border-box;
    padding: 22px;
    border-top: solid 1px #e8e8e8;
    border-right: solid 1px #e8e8e8;
    border-bottom: solid 1px #e8e8e8;
    font-size: 14px; }
    .graph-hover-wrapper .graph-hover-content div {
      margin-top: 2px; }
    .graph-hover-wrapper .graph-hover-content .building-type {
      text-transform: capitalize; }
    .graph-hover-wrapper .graph-hover-content .building-type, .graph-hover-wrapper .graph-hover-content .this-building, .graph-hover-wrapper .graph-hover-content .em-dash {
      color: #949494; }
    .graph-hover-wrapper .graph-hover-content .type-average {
      font-weight: bold; }
    .graph-hover-wrapper .graph-hover-content .percent {
      font-weight: bold; }
      .graph-hover-wrapper .graph-hover-content .percent .below-average {
        color: #93bb45; }
      .graph-hover-wrapper .graph-hover-content .percent .above-average {
        color: red; }

.building-stats {
  overflow-y: auto;
  overflow-x: hidden; }
  .building-stats .close-button {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #2D3138;
    text-align: center;
    font-size: 24px;
    background-color: white;
    top: 15px;
    right: 25px;
    cursor: pointer;
    transition: transform 200ms ease; }
    .building-stats .close-button:hover {
      background-color: #2D3138;
      color: white;
      transform: rotate(-90deg);
      transition: transform 200ms ease; }
  .building-stats .building-type-icon {
    height: 96px; }
  .building-stats h1 {
    margin-top: 0; }
  .building-stats .stats-content-wrapper-1, .building-stats .stats-content-wrapper-2 {
    padding-left: 32px;
    padding-right: 32px; }
    .building-stats .stats-content-wrapper-1.top-list, .building-stats .stats-content-wrapper-2.top-list {
      padding-left: 0px;
      padding-right: 0px; }
  .building-stats .stats-content-wrapper-1.eui-selected .eui {
    order: 1; }
    .building-stats .stats-content-wrapper-1.eui-selected .eui .switch-link {
      display: none; }
  .building-stats .stats-content-wrapper-1.eui-selected .bar-graph {
    order: 2; }
  .building-stats .stats-content-wrapper-1.eui-selected .annual-usage {
    order: 3; }
    .building-stats .stats-content-wrapper-1.eui-selected .annual-usage svg {
      display: none; }
  .building-stats .stats-content-wrapper-1.annualUsage-selected .eui {
    order: 3; }
    .building-stats .stats-content-wrapper-1.annualUsage-selected .eui svg {
      display: none; }
  .building-stats .stats-content-wrapper-1.annualUsage-selected .bar-graph {
    order: 2; }
  .building-stats .stats-content-wrapper-1.annualUsage-selected .annual-usage {
    order: 1; }
    .building-stats .stats-content-wrapper-1.annualUsage-selected .annual-usage .switch-link {
      display: none; }
  .building-stats .stats-content-wrapper-1 .annual-usage, .building-stats .stats-content-wrapper-1 .eui {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    .building-stats .stats-content-wrapper-1 .annual-usage .usage-value, .building-stats .stats-content-wrapper-1 .eui .usage-value {
      margin-right: 15px; }
    .building-stats .stats-content-wrapper-1 .annual-usage .switch-link, .building-stats .stats-content-wrapper-1 .eui .switch-link {
      font-size: 14px;
      color: #949494;
      /* Remove extra space inside buttons in Firefox */ }
      .building-stats .stats-content-wrapper-1 .annual-usage .switch-link button, .building-stats .stats-content-wrapper-1 .annual-usage .switch-link button:focus, .building-stats .stats-content-wrapper-1 .annual-usage .switch-link button:active, .building-stats .stats-content-wrapper-1 .eui .switch-link button, .building-stats .stats-content-wrapper-1 .eui .switch-link button:focus, .building-stats .stats-content-wrapper-1 .eui .switch-link button:active {
        /* Remove all decorations to look like normal text */
        background: none;
        border: none;
        display: inline;
        font: inherit;
        margin: 0;
        padding: 0;
        /* Additional styles to look like a link */
        color: #949494;
        cursor: pointer;
        text-decoration: underline; }
      .building-stats .stats-content-wrapper-1 .annual-usage .switch-link button::-moz-focus-inner, .building-stats .stats-content-wrapper-1 .eui .switch-link button::-moz-focus-inner {
        border: none;
        padding: 0; }
  .building-stats ul {
    display: flex;
    list-style: none;
    padding-left: 0; }
  .building-stats .stats-content-wrapper-1 ul {
    flex-direction: column; }
  .building-stats .stats-content-wrapper-2 ul {
    flex-direction: row;
    flex-wrap: wrap; }
    .building-stats .stats-content-wrapper-2 ul li {
      width: 50%;
      margin-bottom: 12px; }
  .building-stats .value-title {
    color: #808080;
    margin-bottom: 5px; }
  .building-stats .value {
    font-weight: bold;
    display: flex;
    flex-direction: row; }
    .building-stats .value .svg-dot {
      margin-right: 8px; }
    .building-stats .value .eui-units {
      font-size: 13px;
      margin-left: 2px;
      margin-top: 1px; }
  .building-stats .bar-graph {
    height: 150px;
    margin-top: 25px;
    margin-bottom: 10px; }
  .building-stats .view-ranking {
    background-color: #F5F5F5;
    display: flex;
    height: 44px;
    justify-content: space-between;
    border-top: 1px solid #CDCDCF;
    border-bottom: 1px solid #CDCDCF;
    vertical-align: middle;
    line-height: 44px;
    padding: 0 35px;
    cursor: pointer; }
    .building-stats .view-ranking span {
      color: #2D3138;
      font-weight: 600;
      letter-spacing: 0.6px; }
    .building-stats .view-ranking img {
      height: 60%;
      margin-top: 8px;
      transform: rotate(180deg);
      transition: ease 0.4s all; }
    .building-stats .view-ranking:hover img {
      margin-right: -8px; }
  .building-stats .primary-usage, .building-stats .secondary-usage {
    text-transform: capitalize; }
    .building-stats .primary-usage:after, .building-stats .secondary-usage:after {
      content: "\00a0"; }
  .building-stats .annual-cost, .building-stats .description-wrapper, .building-stats .certification-wrapper {
    width: 100% !important; }
  .building-stats .green-building {
    padding-left: 50%; }
  .building-stats .building-type-description {
    line-height: 1.67; }
  .building-stats .certification-wrapper span {
    text-transform: capitalize; }
    .building-stats .certification-wrapper span:nth-of-type(2):before {
      content: ", "; }
  .building-stats .certification-wrapper img {
    margin-right: 25px; }
  .building-stats button.building-data-button {
    width: 100%;
    height: 55px;
    margin: 15px auto;
    border-radius: 1px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border: solid 1px #000000;
    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: white;
    cursor: pointer;
    transition: background 0.3s; }
    .building-stats button.building-data-button:hover {
      background: #2D323C;
      transition: background 0.3s; }
  .building-stats .highcharts-root {
    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
    .building-stats .highcharts-root .highcharts-plot-lines-0 {
      stroke-width: 1px;
      transition: stroke-width 0.3s; }
    .building-stats .highcharts-root:hover .highcharts-plot-lines-0 path {
      stroke-width: 2px;
      transition: stroke-width 0.3s; }
  .building-stats .highcharts-xaxis .highcharts-tick {
    stroke-width: 0; }
  .building-stats .highcharts-xaxis .highcharts-axis-line {
    stroke-width: 0; }
