.building-projects {
  background-color: #eeedee; }
  .building-projects .building-commissioning .project-grid-wrapper .buildingProjects-title {
    width: 90%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    margin: 60px auto 30px;
    color: #3C414B; }
    .building-projects .building-commissioning .project-grid-wrapper .buildingProjects-title span {
      font-size: 14px;
      line-height: 26px;
      font-weight: 100;
      color: rgba(46, 56, 77, 0.7); }
  .building-projects .building-commissioning .chart-container {
    width: 90%;
    margin: 30px auto; }
  .building-projects .building-commissioning .table-container {
    width: 90%;
    margin: auto; }
  .building-projects .building-commissioning .grid-container {
    width: 90%;
    margin: 30px auto;
    padding-bottom: 45px; }
  .building-projects .building-commissioning .chart-title-electricity {
    color: #98be48; }
  .building-projects .building-commissioning .chart-title-steam {
    color: #f49233; }
  .building-projects .building-commissioning .chart-title-chilledWater {
    color: #25a9c6; }
  .building-projects .building-commissioning .chart-title-totalSavings {
    color: #3C414B; }
  .building-projects .loading {
    width: 900px;
    margin: 40px auto; }
  .building-projects .buildComm-info-wrap {
    width: 70%;
    margin: 30px auto;
    line-height: 20px;
    color: #3C414B; }
    .building-projects .buildComm-info-wrap .img-quote-wrap {
      display: flex;
      align-items: center;
      margin-top: 30px; }
      .building-projects .buildComm-info-wrap .img-quote-wrap img {
        display: block;
        width: 20%;
        flex-shrink: 0;
        margin-left: -15px; }
      .building-projects .buildComm-info-wrap .img-quote-wrap .buildComm-quote {
        padding-left: 60px;
        color: rgba(60, 65, 75, 0.7); }
        .building-projects .buildComm-info-wrap .img-quote-wrap .buildComm-quote .buildComm-name {
          margin-top: 20px; }
    .building-projects .buildComm-info-wrap .buildComm-description {
      margin-bottom: 25px; }
  .building-projects .opportunities-log .buildingProjects-title,
  .building-projects .hvac-scheduling .buildingProjects-title {
    padding-left: 32px; }
    .building-projects .opportunities-log .buildingProjects-title span,
    .building-projects .hvac-scheduling .buildingProjects-title span {
      font-size: 15px;
      margin-left: 190px;
      color: #3C414B; }
  .building-projects .year-selection {
    position: absolute;
    right: 10%;
    width: 100px;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid #D8D8D8;
    color: black;
    font-weight: bold;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer; }

.opportunities-table,
.hvac-table,
.holiday-table {
  width: 100%;
  border-top: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8; }
  .opportunities-table .opportunities-table-header,
  .opportunities-table .hvac-table-header,
  .opportunities-table .holiday-table-header,
  .hvac-table .opportunities-table-header,
  .hvac-table .hvac-table-header,
  .hvac-table .holiday-table-header,
  .holiday-table .opportunities-table-header,
  .holiday-table .hvac-table-header,
  .holiday-table .holiday-table-header {
    display: flex;
    width: 100%;
    width: -webkit-fill-available;
    background: #F6F6F6;
    font-weight: 600; }
  .opportunities-table .opportunities-table-data,
  .opportunities-table .hvac-table-data,
  .opportunities-table .holiday-table-data,
  .hvac-table .opportunities-table-data,
  .hvac-table .hvac-table-data,
  .hvac-table .holiday-table-data,
  .holiday-table .opportunities-table-data,
  .holiday-table .hvac-table-data,
  .holiday-table .holiday-table-data {
    display: flex;
    flex-wrap: wrap; }
    .opportunities-table .opportunities-table-data .opportunities-data-row,
    .opportunities-table .opportunities-table-data .hvac-data-row,
    .opportunities-table .opportunities-table-data .holiday-data-row,
    .opportunities-table .hvac-table-data .opportunities-data-row,
    .opportunities-table .hvac-table-data .hvac-data-row,
    .opportunities-table .hvac-table-data .holiday-data-row,
    .opportunities-table .holiday-table-data .opportunities-data-row,
    .opportunities-table .holiday-table-data .hvac-data-row,
    .opportunities-table .holiday-table-data .holiday-data-row,
    .hvac-table .opportunities-table-data .opportunities-data-row,
    .hvac-table .opportunities-table-data .hvac-data-row,
    .hvac-table .opportunities-table-data .holiday-data-row,
    .hvac-table .hvac-table-data .opportunities-data-row,
    .hvac-table .hvac-table-data .hvac-data-row,
    .hvac-table .hvac-table-data .holiday-data-row,
    .hvac-table .holiday-table-data .opportunities-data-row,
    .hvac-table .holiday-table-data .hvac-data-row,
    .hvac-table .holiday-table-data .holiday-data-row,
    .holiday-table .opportunities-table-data .opportunities-data-row,
    .holiday-table .opportunities-table-data .hvac-data-row,
    .holiday-table .opportunities-table-data .holiday-data-row,
    .holiday-table .hvac-table-data .opportunities-data-row,
    .holiday-table .hvac-table-data .hvac-data-row,
    .holiday-table .hvac-table-data .holiday-data-row,
    .holiday-table .holiday-table-data .opportunities-data-row,
    .holiday-table .holiday-table-data .hvac-data-row,
    .holiday-table .holiday-table-data .holiday-data-row {
      display: flex;
      width: 100%;
      width: -webkit-fill-available; }
  .opportunities-table .opportunities-table-content,
  .opportunities-table .hvac-table-content,
  .opportunities-table .holiday-table-content,
  .hvac-table .opportunities-table-content,
  .hvac-table .hvac-table-content,
  .hvac-table .holiday-table-content,
  .holiday-table .opportunities-table-content,
  .holiday-table .hvac-table-content,
  .holiday-table .holiday-table-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px;
    border: 1px solid #D8D8D8;
    border-top: none;
    border-right: none; }
