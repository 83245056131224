.usage-tab {
  padding-top: 40px;
  max-width: 1050px;
  width: 100%;
  margin: auto;
  font-size: 16px;
  line-height: 20px; }
  .usage-tab .water-card {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 30px 3% 40px 3%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    object-fit: contain; }
  .usage-tab .water-usage .water-headline {
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    line-height: 22px;
    padding: 20px 0; }
  .usage-tab .water-usage .water-graph {
    width: 100%;
    display: flex;
    margin-bottom: 70px;
    justify-content: space-between; }
    .usage-tab .water-usage .water-graph .water-chart {
      width: 79%; }
    .usage-tab .water-usage .water-graph .water-chart-selection {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px; }
    .usage-tab .water-usage .water-graph .csv-div .water-csv .csv-link .water-csv-button {
      width: 100%;
      max-width: 140px;
      height: 20px;
      border: 2px solid #EEEDEE;
      background-color: white;
      border-radius: 3px;
      color: #2D3138;
      font-weight: 500;
      text-align: center;
      display: flex;
      padding: 9px 24px 9px; }
      .usage-tab .water-usage .water-graph .csv-div .water-csv .csv-link .water-csv-button .download-img {
        width: 11px;
        height: 13px;
        margin-top: 3px;
        margin-right: 18px; }
      .usage-tab .water-usage .water-graph .csv-div .water-csv .csv-link .water-csv-button:hover {
        border: 2px solid #2D3138;
        font-weight: 600; }
    .usage-tab .water-usage .water-graph .water-usage-graph {
      display: flex; }
      .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options {
        min-height: 60px;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option {
          display: flex;
          font-size: 16px;
          font-weight: 550; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option {
          width: 100%;
          text-align: left;
          position: relative;
          display: block;
          cursor: pointer;
          margin: 10px 0 0 0px; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input {
          display: none;
          position: absolute;
          cursor: pointer; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option .water-graph-option-label:before {
          content: '';
          padding: 0px;
          display: inline-block;
          height: 16px;
          width: 16px;
          border: solid 1px #d6d6d6;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 7px;
          vertical-align: middle; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input + .water-graph-option-label:before {
          color: white;
          background-color: white;
          min-width: 16px; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label.domestic:before {
          background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23336CC8' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
          opacity: 1; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label.utility:before {
          background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2366D1EE' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
          opacity: 1; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label.grid:before {
          background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
          opacity: 1; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label.agriculture:before {
          background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23194D6D' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
          opacity: 1; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label.fisheries:before {
          background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2381FCF2' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
          opacity: 1; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input + .water-graph-option-label {
          font-size: 14px;
          color: #a6a6a6;
          font-weight: 450;
          display: flex;
          margin-top: 1px; }
        .usage-tab .water-usage .water-graph .water-usage-graph .water-graph-options .water-graph-option input:checked + .water-graph-option-label {
          color: black;
          display: flex; }
  .usage-tab .water-usage .water-story {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    font-size: 14px; }
    .usage-tab .water-usage .water-story div {
      display: inline-block;
      font-weight: 450; }
      .usage-tab .water-usage .water-story div p {
        font-weight: 10;
        color: rgba(45, 49, 56, 0.7);
        margin-top: 12px;
        line-height: 17px; }
    .usage-tab .water-usage .water-story .water-story-column1 {
      width: 45%;
      flex-direction: column; }
      .usage-tab .water-usage .water-story .water-story-column1 .water-story-box {
        display: flex;
        flex-direction: row; }
    .usage-tab .water-usage .water-story .water-story-column2 {
      width: 45%;
      flex-direction: column; }
      .usage-tab .water-usage .water-story .water-story-column2 .water-story-box {
        display: flex;
        flex-direction: row;
        margin-bottom: 25px; }
