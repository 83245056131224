.dashboards-menu-navigation {
  position: absolute;
  left: 15%;
  padding-top: 24px; }
  .dashboards-menu-navigation .dashboards-link,
  .dashboards-menu-navigation .dashboards-menu {
    width: 140px;
    height: 30px;
    padding-top: 12px;
    padding-left: 12px;
    border-radius: 2px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    font-weight: 600;
    background-color: #3c414b;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #9DA0A5;
    text-decoration: none;
    transition: ease all 0.3s;
    display: flex; }
  .dashboards-menu-navigation .dashboards-link {
    justify-content: center; }
    .dashboards-menu-navigation .dashboards-link img {
      height: 20px;
      width: 20px;
      padding: 0 4px;
      transform: rotate(-90deg);
      filter: brightness(320%); }
  .dashboards-menu-navigation .dashboards-menu {
    display: flex;
    align-items: center;
    width: 340px;
    height: 40px;
    padding: 5px 30px; }
    .dashboards-menu-navigation .dashboards-menu img {
      margin-right: 20px;
      width: 32px;
      height: 32px; }
    .dashboards-menu-navigation .dashboards-menu:hover {
      background-color: #5A5D65;
      color: white; }
      .dashboards-menu-navigation .dashboards-menu:hover img {
        filter: brightness(125%);
        -webkit-filter: brightness(125%); }

a {
  text-decoration: none; }

.dashboards-link:hover {
  background-color: #5A5D65;
  color: white; }
  .dashboards-link:hover img {
    filter: brightness(500%);
    -webkit-filter: brightness(500%); }
