.home {
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 200px;
  background-color: transparent;
  border-radius: 0 200px 0 0; }
  .home > img {
    width: 100%;
    height: 100%;
    border-radius: 0 200px 0 0; }

.next {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #022851;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  align-self: flex-end;
  border: none;
  margin-bottom: 30px;
  margin-right: 90px; }
  .next > img {
    height: 60px;
    width: 60px;
    margin-left: 20px; }
