.mobile-header {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: 460px;
  z-index: 100;
  color: white;
  padding: 50px 40px;
  box-sizing: border-box;
  overflow: hidden; }
  .mobile-header .mobile-header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    z-index: -1;
    overflow: hidden; }
    .mobile-header .mobile-header-image img {
      object-fit: cover;
      object-position: 0 0;
      width: inherit;
      height: inherit;
      z-index: inherit; }
  .mobile-header .phone-image {
    z-index: 100;
    position: relative;
    padding: 55px 40px;
    display: flex;
    flex-direction: column;
    background-color: #1a1e27;
    box-shadow: 3px -4px 15px 0 rgba(0, 0, 0, 0.5);
    border-radius: 41px; }
    .mobile-header .phone-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0 auto;
      z-index: -1; }
    .mobile-header .phone-image h3 {
      font-size: 15px;
      font-weight: bold;
      font-style: italic;
      color: #F7931E;
      text-align: center;
      line-height: 1.2;
      margin-top: 40px; }
      .mobile-header .phone-image h3 em {
        border-bottom: 2px solid #F7931E;
        padding-bottom: 3px; }
    .mobile-header .phone-image p {
      font-size: 12px;
      font-style: italic;
      line-height: 1.14;
      max-width: 170px;
      margin-left: auto;
      margin-right: auto; }
      .mobile-header .phone-image p em {
        font-weight: bold; }
  .mobile-header h1 {
    font-size: 24px;
    line-height: 1.2; }
  .mobile-header h2 {
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 40px; }
  .mobile-header h1, .mobile-header h2 {
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px; }

.what-can-you-do {
  padding: 40px;
  text-align: center;
  margin: 0 auto;
  box-shadow: 0 3px 2px 0 rgba(20, 20, 20, 0.15);
  position: relative; }
  .what-can-you-do h2 {
    font-size: 15px; }
  .what-can-you-do ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 35px;
    max-width: 250px; }
    .what-can-you-do ul li {
      font-size: 14.5px;
      line-height: 1.14;
      color: #616161;
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .what-can-you-do ul li em {
        font-style: normal;
        font-weight: bold; }
      .what-can-you-do ul li img {
        width: 80px; }
        .what-can-you-do ul li img.graph-icon {
          width: 65px; }

.we-all-play-a-part {
  background: #E8E8E8;
  color: #1a1a1a;
  padding: 60px 35px 100px 35px;
  font-size: 14.5px;
  line-height: 1.4; }
  .we-all-play-a-part p {
    max-width: 280px;
    margin: 0 auto; }
