.campus-energy {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  margin: auto;
  justify-content: center; }
  .campus-energy h1 {
    text-align: center;
    width: 300px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 20px; }
  .campus-energy .campus-energy-image {
    width: 85%;
    object-fit: contain;
    margin: auto; }
  .campus-energy .campus-energy-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .campus-energy .campus-energy-description .campus-energy-description-banner {
      height: 73px;
      font-size: 17px;
      font-weight: bold;
      display: flex;
      background-color: #FFFFFF;
      margin: 30px auto auto auto;
      text-align: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;
      align-items: center; }
      .campus-energy .campus-energy-description .campus-energy-description-banner button {
        font-weight: bold;
        font-size: 17px; }
    .campus-energy .campus-energy-description .campus-energy-description-content {
      background-color: #FCFCFC;
      box-shadow: 0 -4px 3px #EAEAEA;
      padding: 0 60px; }
      .campus-energy .campus-energy-description .campus-energy-description-content .campus-energy-description-content-title {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid black;
        padding-top: 20px;
        line-height: 1.5; }
      .campus-energy .campus-energy-description .campus-energy-description-content .campus-energy-description-content-description {
        font-size: 14px;
        line-height: 1.5;
        padding-bottom: 20px; }
