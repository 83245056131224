.mobile-menu {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: #212832;
  position: fixed;
  top: 0;
  left: -110%;
  transition: ease all 0.4s;
  cursor: pointer;
  z-index: 1000; }
  .mobile-menu.open {
    left: 0; }
  .mobile-menu .mobile-menu-options {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 8%;
    justify-content: space-between; }
    .mobile-menu .mobile-menu-options .mobile-menu-option {
      display: flex;
      flex-direction: column;
      align-content: center;
      height: 110px;
      width: 110px;
      padding: 10px;
      background: #343841;
      border-radius: 17px;
      margin: 0 10px 17px;
      font-size: 14px;
      color: #AAAAAA;
      text-decoration: none;
      line-height: 16px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      transition: ease all 0.3s; }
      .mobile-menu .mobile-menu-options .mobile-menu-option img {
        height: 52px;
        width: 52px;
        margin: 10px auto 12px;
        opacity: 0.5; }
      .mobile-menu .mobile-menu-options .mobile-menu-option.current {
        color: white; }
        .mobile-menu .mobile-menu-options .mobile-menu-option.current img {
          opacity: 1; }
    @media (max-width: 360px) {
      .mobile-menu .mobile-menu-options {
        padding: 20px 3%; } }
    @media (min-width: 425px) {
      .mobile-menu .mobile-menu-options {
        padding: 20px 13%; } }
