.building-loading {
  padding: 40px 20%;
  max-width: 900px;
  margin: auto; }
  .building-loading a {
    text-decoration: none; }
  @media (max-width: 850px) {
    .building-loading {
      padding: 0;
      padding-bottom: 20px; } }

.loading-wrapper {
  width: 100%;
  align-content: center;
  text-align: center;
  color: black; }
  .loading-wrapper .loading-pulse {
    display: inline-block;
    position: relative;
    width: 34px;
    height: 34px;
    float: right;
    clear: right; }
    @media (max-width: 850px) {
      .loading-wrapper .loading-pulse {
        padding-right: 20px; } }
  .loading-wrapper .loading-pulse div {
    position: absolute;
    border: 4px solid #98bf47;
    opacity: 1;
    border-radius: 50%;
    animation: loading-pulse 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  .loading-wrapper .loading-pulse div:nth-child(2) {
    animation-delay: -0.75s; }

@keyframes loading-pulse {
  0% {
    top: 14px;
    left: 14px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 29px;
    height: 29px;
    opacity: 0; } }
  .loading-wrapper .loading-image {
    width: 65%;
    max-height: 200px;
    display: flex;
    margin: auto;
    padding: 20px 0;
    padding-left: 34px; }
    .loading-wrapper .loading-image .loading-image-file {
      margin: auto;
      max-height: inherit;
      max-width: 100%; }
    @media (max-width: 850px) {
      .loading-wrapper .loading-image {
        width: 90%;
        padding: 0;
        padding-left: 0; } }
  .loading-wrapper .loading-headline {
    width: 100%;
    padding: 20px 0 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 32px; }
  .loading-wrapper .loading-body {
    width: 100%;
    font-size: 18px;
    padding-bottom: 20px; }
    .loading-wrapper .loading-body span {
      color: #00A9CA;
      text-decoration: underline;
      font-weight: 600; }
