.total-savings .total-savings-title {
  font-size: 17px;
  padding-top: 10px;
  line-height: 22px;
  font-weight: 600; }

.total-savings .total-savings-description {
  width: 95%;
  margin: 30px 0;
  line-height: 22px; }

.total-savings .total-savings-overview {
  width: 100%;
  display: flex;
  margin: 30px auto 50px;
  justify-content: space-between; }
  .total-savings .total-savings-overview .savings-overview-table {
    width: 36%;
    height: 100%;
    border-top: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8; }
    .total-savings .total-savings-overview .savings-overview-table .overview-table-header {
      display: flex;
      width: 100%;
      width: -webkit-fill-available;
      font-weight: 600; }
    .total-savings .total-savings-overview .savings-overview-table .overview-data-row {
      display: flex;
      width: 100%;
      width: -webkit-fill-available; }
    .total-savings .total-savings-overview .savings-overview-table .overview-table-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 20px;
      border: 1px solid #D8D8D8;
      border-top: none;
      border-right: none; }
      .total-savings .total-savings-overview .savings-overview-table .overview-table-content.right {
        text-align: right;
        border-left: none; }
  .total-savings .total-savings-overview .savings-overview-chart {
    width: 55%; }

.total-savings .total-savings-current {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -40px -60px;
  padding: 30px 40px 60px;
  background: #F6F6F7; }
  .total-savings .total-savings-current .savings-current-svg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 40px; }
    .total-savings .total-savings-current .savings-current-svg path {
      animation: progress 3s ease-out forwards; }

@keyframes progress {
  0% {
    stroke-dasharray: none; }
  33% {
    stroke-dasharray: 0 1000; } }
    .total-savings .total-savings-current .savings-current-svg line {
      stroke: orange;
      stroke-width: 18; }
    .total-savings .total-savings-current .savings-current-svg svg {
      width: 100%;
      border: 4px solid #D8D8D8;
      border-radius: 16px;
      background: #D8D8D8; }
    .total-savings .total-savings-current .savings-current-svg .stroke-3 {
      stroke: blue; }
  .total-savings .total-savings-current .savings-current-values {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .total-savings .total-savings-current .savings-current-values .savings-current-value {
      display: flex;
      padding: 10px 12px 8px;
      background-color: white;
      border: 2px solid #D8D8D8; }
      .total-savings .total-savings-current .savings-current-values .savings-current-value .savings-current-value-color {
        padding-right: 12px; }
      .total-savings .total-savings-current .savings-current-values .savings-current-value b {
        padding-left: 6px; }

.total-savings .savings-current-table {
  width: 40%;
  background: white;
  border-top: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  margin-top: 30px; }
  .total-savings .savings-current-table .current-table-header {
    display: flex;
    width: 100%;
    width: -webkit-fill-available;
    font-weight: 600; }
  .total-savings .savings-current-table .current-data-row {
    display: flex;
    width: 100%;
    width: -webkit-fill-available; }
  .total-savings .savings-current-table .current-table-content {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    border: 1px solid #D8D8D8;
    border-top: none;
    border-right: none; }
    .total-savings .savings-current-table .current-table-content.right {
      text-align: right;
      border-left: none; }
    .total-savings .savings-current-table .current-table-content .current-table-dot {
      margin-right: 10px; }

.total-savings .savings-current-graph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 55%;
  margin-top: 30px; }
  .total-savings .savings-current-graph .savings-current-circle {
    width: 100%; }
  .total-savings .savings-current-graph .savings-current-hover {
    margin-top: 20px;
    text-align: center; }
