.modal-content,
.modal-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900; }

.modal-content-overlay {
  background: rgba(0, 0, 0, 0.3); }

.modal-content-background {
  height: 62%;
  min-height: 700px;
  width: 60%;
  min-width: 650px;
  max-width: 1280px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #FFF;
  z-index: 950;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.modal-content-background-image {
  height: 62%;
  min-height: 700px;
  width: 60%;
  min-width: 650px;
  max-width: 1280px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #FFF;
  background-image: url("../images/welcome-background.png");
  background-size: cover;
  z-index: 950;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.modal-content-close-button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 80px;
  cursor: pointer;
  padding: 15px;
  line-height: 20px; }
  .modal-content-close-button .close-icon {
    font-size: 30px;
    margin-right: 5px; }
  .modal-content-close-button .close-text {
    font-size: 18px; }

.modal-nav-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 30%;
  width: 40%;
  padding: 15px;
  line-height: 20px; }
  .modal-nav-wrapper .modal-content-button-set {
    display: flex;
    column-gap: 15px;
    margin: 5% 0; }
    .modal-nav-wrapper .modal-content-button-set .modal-content-back-button {
      min-width: 5rem;
      border: solid #31353e;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      background-color: #FFF;
      color: #31353E;
      text-align: center;
      padding: 8px 18px;
      cursor: pointer;
      transition: ease all 0.3s; }
    .modal-nav-wrapper .modal-content-button-set .modal-content-back-button:hover {
      background-color: #99BE4F;
      color: black; }
    .modal-nav-wrapper .modal-content-button-set .modal-content-next-button {
      min-width: 5rem;
      border: solid #31353e;
      background-color: #31353e;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      color: #FFF;
      text-align: center;
      padding: 8px 18px;
      cursor: pointer;
      transition: ease all 0.3s; }
    .modal-nav-wrapper .modal-content-button-set .modal-content-next-button:hover {
      background-color: #99BE4F;
      color: black; }
  .modal-nav-wrapper .modal-content-toggle-image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 8% 0; }

.info-modal-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 18%;
  left: 30%;
  width: 40%;
  cursor: pointer;
  padding: 15px;
  line-height: 20px; }
  .info-modal-button-wrapper .info-modal-back-button {
    min-width: 13rem;
    border: solid #31353e;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    background-color: #FFF;
    color: #31353E;
    text-align: center;
    padding: 8px 10px;
    cursor: pointer;
    transition: ease all 0.3s;
    z-index: 3; }
  .info-modal-button-wrapper .info-modal-back-button:hover {
    background-color: #99BE4F;
    color: black; }

.info-modal-wrapper {
  height: 100%;
  display: flex; }
  .info-modal-wrapper .info-modal-column1 {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    width: 31%;
    margin: 5% 8% 15% 8%;
    font-size: 16px;
    line-height: 21px; }
    .info-modal-wrapper .info-modal-column1 .info-modal-headline {
      font-size: 28px;
      font-weight: 700;
      line-height: 150%;
      white-space: pre-line; }
    .info-modal-wrapper .info-modal-column1 p {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      color: #31353E; }
    .info-modal-wrapper .info-modal-column1 .info-toggle-button {
      text-decoration: underline;
      color: #4A676E;
      cursor: pointer; }
  .info-modal-wrapper .info-modal-column2 {
    width: 65%; }
    .info-modal-wrapper .info-modal-column2 img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .info-modal-wrapper .info-modal-column2:before {
    content: '';
    position: absolute;
    top: 0;
    left: 43%;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #FFFFFF 5%, #FFFFFF 5%, rgba(255, 255, 255, 0) 12%);
    z-index: 1; }

.modal-content-column {
  height: inherit; }
  .modal-content-column .modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5%;
    padding: 6% 0;
    height: 100%; }
    .modal-content-column .modal-content-wrapper .modal-building-img {
      width: 75%; }
      .modal-content-column .modal-content-wrapper .modal-building-img img {
        width: 100%; }
    .modal-content-column .modal-content-wrapper .modal-content-headline {
      font-size: 32px;
      text-align: center;
      line-height: 150%; }
      .modal-content-column .modal-content-wrapper .modal-content-headline .modal-content-normal {
        font-size: 24px;
        display: inline; }
    .modal-content-column .modal-content-wrapper .modal-content-subheadline {
      font-weight: 700;
      font-size: 28px;
      line-height: 150%; }
      .modal-content-column .modal-content-wrapper .modal-content-subheadline div {
        font-size: 16px;
        line-height: 150%;
        padding: 0;
        text-align: center;
        font-weight: 400; }
    .modal-content-column .modal-content-wrapper p {
      width: 38%;
      text-align: center;
      font-size: 16px;
      line-height: 150%; }
    .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; }
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1,
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 42%;
        line-height: 150%; }
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1 .modal-content-headline {
        font-size: 28px;
        font-weight: 700;
        padding-right: 20%;
        text-align: left; }
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1 p {
        width: 80%;
        font-size: 16px;
        margin: 5% 23% 5% 0;
        text-align: left; }
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1 .modal-content-fraction-wrapper {
        display: flex;
        align-items: center;
        color: #3879BD;
        column-gap: 10px;
        font-size: 14px;
        line-height: 14px; }
        .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1 .modal-content-fraction-wrapper .modal-content-fraction {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column1 .modal-content-fraction-wrapper .modal-content-fraction hr {
            border: none;
            height: 1px;
            width: 100%;
            color: #3879BD;
            background-color: #3879BD;
            margin: 5px 0; }
      .modal-content-column .modal-content-wrapper .modal-content-columns-wrapper .modal-content-column2 img {
        width: 100%;
        height: auto; }
    .modal-content-column .modal-content-wrapper .gif-tiles-wrapper {
      display: flex;
      column-gap: 20px;
      justify-content: center;
      position: relative;
      height: 65%;
      width: 65%; }
      .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile {
        width: 30%;
        height: 100%;
        min-height: 240px;
        min-width: 200px;
        position: relative;
        border-radius: 5px;
        cursor: pointer; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .static {
          width: 100%;
          height: 100%;
          position: absolute;
          box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.15);
          border-radius: 5px; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .static:hover {
          opacity: 0; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .filter-by-eui {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0.2) 100%), url("../images/filter-by-eui.png");
          background-size: cover;
          background-position: center; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .filter-by-building-type {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0.2) 100%), url("../images/filter-by-building-type.png");
          background-size: cover;
          background-position: center; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .view-building-data {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0.2) 100%), url("../images/view-building-data.png");
          background-size: cover;
          background-position: center; }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4); }
        .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile .gif-name {
          position: absolute;
          bottom: 15px;
          text-align: center;
          z-index: 20;
          width: 100%;
          font-size: 14px;
          line-height: 150%;
          font-weight: 500; }
      .modal-content-column .modal-content-wrapper .gif-tiles-wrapper .gif-tile::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, white 100%); }
