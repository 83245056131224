.container {
  width: 58%;
  max-height: 890px; }
  .container > h1 {
    color: #022851;
    font-size: 72px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 86px;
    margin-bottom: 30px; }

.info {
  display: flex;
  align-items: flex-end;
  column-gap: 32px;
  margin-bottom: 27px; }
  .info > h2 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.1;
    color: #022851;
    min-width: 210px; }
    .info > h2 > span {
      font-size: 20px;
      font-weight: normal;
      font-style: italic;
      color: black; }
  .info > p {
    font-size: 20px;
    line-height: 1.2;
    font-style: italic;
    margin: 0;
    max-width: 75%; }
