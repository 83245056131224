.chcp-tooltip {
  position: relative;
  display: inline;
  margin-left: 10px; }
  .chcp-tooltip .chcp-tooltip-icon {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-top: -4px;
    transition: ease all 0.3s; }
    .chcp-tooltip .chcp-tooltip-icon:hover {
      width: 18px;
      height: 18px; }
  .chcp-tooltip .chcp-tooltip-bubble {
    width: 360px;
    height: 95px;
    position: absolute;
    top: -40px;
    left: 14px;
    padding-left: 9px;
    z-index: 100; }
    .chcp-tooltip .chcp-tooltip-bubble:after {
      content: '';
      position: absolute;
      border-right: 9px solid #F6F6F6;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      filter: drop-shadow(-3px 2px 2px rgba(0, 0, 0, 0.16)); }
  .chcp-tooltip .chcp-tooltip-content {
    background: #F6F6F6;
    color: #3c414b;
    padding: 30px 20px;
    font-size: 14px;
    line-height: 17px;
    border: 3px solid #EEEDEE;
    border-left: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
