.ewc-mobile-wrapper {
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 200px solid #2C3037; }
  .ewc-mobile-wrapper .ewc-mobile-header {
    height: 70%;
    margin: 0 auto 10px;
    padding: 0 5% 15px;
    display: flex;
    max-width: 900px;
    justify-content: space-between; }
    .ewc-mobile-wrapper .ewc-mobile-header .ewc-mobile-header-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto; }
      .ewc-mobile-wrapper .ewc-mobile-header .ewc-mobile-header-logo .ewc-mobile-dates {
        text-align: center;
        font-size: 22px;
        color: white;
        padding-top: 15px; }
      .ewc-mobile-wrapper .ewc-mobile-header .ewc-mobile-header-logo img {
        width: 85%;
        min-width: 260px;
        height: auto;
        margin-top: 30px;
        align-self: center; }
  .ewc-mobile-wrapper .ewc-mobile-header-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 265px; }
    .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-detail-box {
      width: 60%;
      padding: 15px 25px 13px;
      background: #212832;
      border-radius: 3px;
      align-self: center;
      text-align: center;
      color: #F5F5F5;
      font-size: 14px;
      line-height: 18px; }
      .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-detail-box .ewc-mobile-header-detail-title {
        font-weight: 600;
        font-style: italic;
        opacity: 0.7; }
      .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-detail-box .ewc-mobile-header-detail-description {
        margin: 7px auto 0;
        opacity: 0.7; }
    .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips {
      width: 75%;
      margin: 30px auto 0; }
      .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip {
        display: flex;
        margin-bottom: 20px; }
        .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip img {
          margin-right: 20px; }
          .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip img.ewc-mobile-header-tip-light {
            margin: 0 25px 0 5px; }
        .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip .header-tip-detail {
          color: white;
          font-size: 14px;
          line-height: 18px; }
          .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip .header-tip-detail .header-tip-header {
            font-weight: 600; }
            .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip .header-tip-detail .header-tip-header.electricity {
              color: #92BD3B; }
            .ewc-mobile-wrapper .ewc-mobile-header-detail .ewc-mobile-header-tips .ewc-mobile-header-tip .header-tip-detail .header-tip-header.water {
              color: #32BBD5; }
