.demand-season {
  height: auto;
  margin-top: 50px; }
  .demand-season .line {
    width: 2px;
    height: 210px;
    margin-top: -40px;
    margin-left: 620px;
    position: absolute;
    background-color: #eeedee; }
  .demand-season .title-wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    background-color: rgba(238, 237, 238, 0.5);
    width: 989px;
    margin: -30px 0 20px -31px;
    padding: 40px 30px 20px 30px;
    border-bottom: 2px solid #eeedee;
    color: #3C414B; }
    .demand-season .title-wrap .step2 {
      width: 68%; }
      .demand-season .title-wrap .step2 div {
        font-weight: bold;
        letter-spacing: 1.65px;
        font-size: 17px; }
      .demand-season .title-wrap .step2 p {
        width: 90%;
        margin-top: 10px;
        margin-bottom: 30px;
        line-height: 1.5;
        color: #2D3138; }
    .demand-season .title-wrap .potential-saving {
      z-index: 1;
      display: flex;
      flex-direction: column;
      width: 33%;
      font-weight: 500;
      color: #3C414B; }
      .demand-season .title-wrap .potential-saving .totaleui {
        display: flex;
        flex-direction: row;
        margin-top: 35px; }
        .demand-season .title-wrap .potential-saving .totaleui .title {
          font-weight: 100;
          color: rgba(46, 56, 77, 0.7); }
        .demand-season .title-wrap .potential-saving .totaleui .eui {
          margin-left: 35px;
          margin-top: -13px;
          width: 120px;
          height: 40px;
          text-align: center;
          vertical-align: middle;
          line-height: 40px;
          border-radius: 5px;
          background-color: white; }
          .demand-season .title-wrap .potential-saving .totaleui .eui.current {
            border: 2px solid #2D3138;
            margin-left: 43px; }
          .demand-season .title-wrap .potential-saving .totaleui .eui.potential {
            border: 2px solid #98BF47; }
  .demand-season .no-data {
    text-align: center;
    margin: 50px auto 20px; }
  .demand-season .select-building {
    display: flex;
    color: #3C414B; }
    .demand-season .select-building .search-box {
      position: relative;
      margin: -14px 0 10px 53px;
      white-space: nowrap;
      box-shadow: none;
      border-radius: 3px; }
      .demand-season .select-building .search-box .search-box-row-building {
        font-weight: 400;
        color: black;
        font-size: 16px; }
  .demand-season .demand-table {
    margin-top: 50px;
    width: 100%; }
    .demand-season .demand-table .table-title {
      display: flex;
      justify-content: space-around;
      background: #eeedee;
      font-size: 15px;
      line-height: 50px; }
      .demand-season .demand-table .table-title .table-column-text {
        width: 100%;
        border: 1px solid #CDCDCF;
        text-align: center;
        color: #2D3138; }
        .demand-season .demand-table .table-title .table-column-text.commodity, .demand-season .demand-table .table-title .table-column-text.total {
          width: 40%; }
        .demand-season .demand-table .table-title .table-column-text.monthly {
          border-left: none;
          border-right: none; }
    .demand-season .demand-table .table-rows {
      display: flex;
      justify-content: space-around;
      line-height: 130px; }
      .demand-season .demand-table .table-rows .table-row-electricity {
        width: 100%;
        border: 1px solid #CDCDCF;
        border-top: none;
        text-align: center; }
        .demand-season .demand-table .table-rows .table-row-electricity.commodity, .demand-season .demand-table .table-rows .table-row-electricity.total {
          width: 40%; }
        .demand-season .demand-table .table-rows .table-row-electricity.chart {
          border-left: none;
          border-right: none;
          padding-top: 10px; }
        .demand-season .demand-table .table-rows .table-row-electricity.total {
          display: flex;
          flex-direction: column;
          font-weight: bold;
          margin-top: -12px; }
          .demand-season .demand-table .table-rows .table-row-electricity.total .eui {
            width: 160px;
            text-align: right; }
          .demand-season .demand-table .table-rows .table-row-electricity.total .reduction {
            width: 160px;
            font-size: 14px;
            font-weight: 10;
            line-height: 20px;
            margin-top: -55px;
            text-align: right;
            color: #818388; }
          .demand-season .demand-table .table-rows .table-row-electricity.total .totaleui {
            height: 80px; }
            .demand-season .demand-table .table-rows .table-row-electricity.total .totaleui .total-header {
              margin-top: -30px;
              margin-bottom: -105px; }
    .demand-season .demand-table .season-background-wrap {
      display: flex;
      justify-content: center;
      position: absolute;
      margin-left: 226px;
      color: #818388;
      font-size: 15px; }
      .demand-season .demand-table .season-background-wrap .season-background {
        width: 133px;
        height: 382px;
        text-align: center;
        padding-top: 10px; }
        .demand-season .demand-table .season-background-wrap .season-background.summer, .demand-season .demand-table .season-background-wrap .season-background.winter {
          background-color: rgba(240, 240, 240, 0.5); }

.commodityinfo-wrap {
  display: flex;
  justify-content: space-around;
  margin-top: 70px; }
  .commodityinfo-wrap .infowrap {
    width: 355px;
    font-size: 15px; }
    .commodityinfo-wrap .infowrap .info.chilledWater, .commodityinfo-wrap .infowrap .info.gas {
      margin-top: 40px; }
    .commodityinfo-wrap .infowrap .info img {
      position: absolute;
      width: 33px;
      margin-left: -55px;
      margin-top: -8px; }
    .commodityinfo-wrap .infowrap .info div {
      margin-left: 50px; }
    .commodityinfo-wrap .infowrap .info p {
      color: #2D3138;
      line-height: 1.2;
      margin-left: 55px; }
