.total-production {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  line-height: 17px; }
  .total-production .total-production-value {
    display: flex;
    flex-direction: column;
    width: 6%;
    min-width: 65px;
    justify-content: space-evenly; }
  .total-production .total-production-circle {
    width: 150px;
    height: 150px;
    margin: -14px -30px; }
  .total-production .total-production-grid {
    display: flex;
    flex-direction: column;
    width: 65%; }
    .total-production .total-production-grid .production-grid-header,
    .total-production .total-production-grid .production-grid-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .total-production .total-production-grid .production-grid-header.steam,
      .total-production .total-production-grid .production-grid-row.steam {
        color: #3C414B; }
      .total-production .total-production-grid .production-grid-header.chilled-water,
      .total-production .total-production-grid .production-grid-row.chilled-water {
        color: #3C414B; }
      .total-production .total-production-grid .production-grid-header .production-grid-column,
      .total-production .total-production-grid .production-grid-row .production-grid-column {
        padding: 14px 10px 10px;
        width: 100%;
        font-size: 15px;
        line-height: 16px;
        border-bottom: 1px solid #EEEDEE;
        border-right: 1px solid #EEEDEE; }
        .total-production .total-production-grid .production-grid-header .production-grid-column img,
        .total-production .total-production-grid .production-grid-row .production-grid-column img {
          vertical-align: middle;
          margin-top: -4px;
          padding-right: 12px; }
        .total-production .total-production-grid .production-grid-header .production-grid-column.column-1,
        .total-production .total-production-grid .production-grid-row .production-grid-column.column-1 {
          border-left: 1px solid #EEEDEE; }
        .total-production .total-production-grid .production-grid-header .production-grid-column.column-2, .total-production .total-production-grid .production-grid-header .production-grid-column.column-3,
        .total-production .total-production-grid .production-grid-row .production-grid-column.column-2,
        .total-production .total-production-grid .production-grid-row .production-grid-column.column-3 {
          padding-right: 20px;
          text-align: right; }
    .total-production .total-production-grid .production-grid-header {
      background-color: #EEEDEE; }
