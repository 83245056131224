.dropdown-menu {
  background: white;
  border: 1px solid #EEEDEE; }
  .dropdown-menu .dropdown-options,
  .dropdown-menu .dropdown-option {
    padding: 10px;
    cursor: pointer;
    background: white; }
    .dropdown-menu .dropdown-options .dropdown-svg,
    .dropdown-menu .dropdown-option .dropdown-svg {
      padding: 0 8px; }
    .dropdown-menu .dropdown-options.active, .dropdown-menu .dropdown-options:hover,
    .dropdown-menu .dropdown-option.active,
    .dropdown-menu .dropdown-option:hover {
      background: #F6F6F6; }
  .dropdown-menu .dropdown-options.active {
    cursor: auto; }
  .dropdown-menu .dropdown-options .dropdown-options-caret {
    float: right;
    margin: 4px 10px 0 0; }
  .dropdown-menu .dropdown-option {
    border-top: 1px solid #EEEDEE; }
