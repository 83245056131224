.ewc-savings-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 15px 0;
  margin-bottom: 30px; }
  .ewc-savings-card-mobile .ewc-savings-card-title {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    width: 100%;
    text-align: center; }
  .ewc-savings-card-mobile .ewc-savings-range-picker {
    display: flex;
    justify-content: space-between;
    width: 50%;
    max-width: 250px;
    margin: auto;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600; }
    .ewc-savings-card-mobile .ewc-savings-range-picker .ewc-savings-range-arrow {
      padding: 2px;
      cursor: pointer; }
      .ewc-savings-card-mobile .ewc-savings-range-picker .ewc-savings-range-arrow.hidden {
        visibility: hidden; }
      .ewc-savings-card-mobile .ewc-savings-range-picker .ewc-savings-range-arrow.right img {
        margin-top: -1px;
        transform: rotate(180deg); }
    .ewc-savings-card-mobile .ewc-savings-range-picker .ewc-savings-range-selection {
      margin-top: 5px; }
  .ewc-savings-card-mobile .ewc-mobile-savings-chart {
    width: 100%;
    max-width: 430px;
    height: 210px;
    margin: 20px auto; }
  .ewc-savings-card-mobile .ewc-savings-stats {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 20px auto;
    line-height: 24px;
    max-width: 350px; }
    .ewc-savings-card-mobile .ewc-savings-stats .ewc-savings-detail {
      display: flex;
      justify-content: space-between; }
      .ewc-savings-card-mobile .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value {
        color: black;
        font-size: 15px; }
        .ewc-savings-card-mobile .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.electricity {
          color: #92BD3B; }
        .ewc-savings-card-mobile .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.water {
          color: #32BBD5; }
        .ewc-savings-card-mobile .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.overuse {
          color: #FC6616; }
  .ewc-savings-card-mobile .ewc-savings-example {
    display: flex;
    width: 80%;
    max-width: 350px;
    margin: 20px auto 30px; }
    .ewc-savings-card-mobile .ewc-savings-example img {
      width: 5%;
      height: auto;
      margin-right: 7%; }
    .ewc-savings-card-mobile .ewc-savings-example .ewc-savings-example-detail {
      width: 85%; }
