.building {
  min-height: 100vh; }

.building-content {
  background-color: #EEEDEE;
  min-height: calc(100vh - 410px); }

.building-demand,
.building-usage,
.building-comparison,
.building-ewc-ranking {
  padding-top: 40px;
  max-width: 1050px;
  margin: 30px auto 0; }
  .building-demand .graph-card,
  .building-usage .graph-card,
  .building-comparison .graph-card,
  .building-ewc-ranking .graph-card {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 30px 3% 40px 3%;
    margin-bottom: 40px; }
  .building-demand .demand-info-icon,
  .building-usage .demand-info-icon,
  .building-comparison .demand-info-icon,
  .building-ewc-ranking .demand-info-icon {
    position: absolute;
    z-index: 1;
    margin-left: 57px;
    margin-top: -2px;
    cursor: pointer; }
  .building-demand .demand-speech-bubble-ds,
  .building-usage .demand-speech-bubble-ds,
  .building-comparison .demand-speech-bubble-ds,
  .building-ewc-ranking .demand-speech-bubble-ds {
    display: none;
    background: #F6F6F6;
    border: 3px solid #EEEDEE;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    font-size: 14px;
    line-height: 17px;
    color: #3C414B;
    margin: -65px 0 0 89px;
    max-width: 400px;
    padding: 5px 15px 0px;
    width: 240px;
    height: 128px;
    position: absolute;
    z-index: 1; }
  .building-demand .demand-speech-bubble-ds p,
  .building-usage .demand-speech-bubble-ds p,
  .building-comparison .demand-speech-bubble-ds p,
  .building-ewc-ranking .demand-speech-bubble-ds p {
    margin-bottom: -5px; }
  .building-demand .demand-speech-bubble-ds p:last-of-type,
  .building-usage .demand-speech-bubble-ds p:last-of-type,
  .building-comparison .demand-speech-bubble-ds p:last-of-type,
  .building-ewc-ranking .demand-speech-bubble-ds p:last-of-type {
    margin-bottom: 0; }
  .building-demand .demand-speech-bubble-ds-arrow,
  .building-usage .demand-speech-bubble-ds-arrow,
  .building-comparison .demand-speech-bubble-ds-arrow,
  .building-ewc-ranking .demand-speech-bubble-ds-arrow {
    border-left: 21px solid transparent;
    border-top: 17px solid #EEEDEE;
    bottom: 48px;
    position: absolute;
    right: 270px; }
  .building-demand .demand-speech-bubble-ds-arrow::before,
  .building-usage .demand-speech-bubble-ds-arrow::before,
  .building-comparison .demand-speech-bubble-ds-arrow::before,
  .building-ewc-ranking .demand-speech-bubble-ds-arrow::before {
    border-left: 20px solid transparent;
    border-bottom: 15px solid #F6F6F6;
    bottom: 18px;
    content: "";
    position: absolute;
    right: 0px; }
  .building-demand .demand-speech-bubble-ds-arrow::after,
  .building-usage .demand-speech-bubble-ds-arrow::after,
  .building-comparison .demand-speech-bubble-ds-arrow::after,
  .building-ewc-ranking .demand-speech-bubble-ds-arrow::after {
    border-left: 20px solid transparent;
    border-top: 15px solid #F6F6F6;
    bottom: 3px;
    content: "";
    position: absolute;
    right: 0px; }
  .building-demand .demand-info-icon:hover + .demand-speech-bubble-ds,
  .building-usage .demand-info-icon:hover + .demand-speech-bubble-ds,
  .building-comparison .demand-info-icon:hover + .demand-speech-bubble-ds,
  .building-ewc-ranking .demand-info-icon:hover + .demand-speech-bubble-ds {
    display: inline; }
  @media (max-width: 850px) {
    .building-demand,
    .building-usage,
    .building-comparison,
    .building-ewc-ranking {
      padding: 20px 0 0;
      max-width: 100%; }
      .building-demand .graph-card,
      .building-usage .graph-card,
      .building-comparison .graph-card,
      .building-ewc-ranking .graph-card {
        padding: 30px 0 20px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16); } }

.building-comparison, .building-usage {
  margin-top: 0px; }

.building-footer {
  padding: 40px 6%;
  max-width: 1120px;
  margin: auto; }

.building-graph {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .building-graph .building-chart {
    height: 400px; }
  .building-graph .building-side-options {
    text-align: center;
    line-height: 18px;
    margin-top: -25px; }
    .building-graph .building-side-options .building-side-button {
      width: 100%;
      max-width: 150px;
      height: 36px;
      border: solid 2px #EEEDEE;
      border-radius: 2px;
      box-sizing: border-box;
      margin: 10px;
      margin-left: 30px;
      padding: 9px 0;
      cursor: pointer;
      background: white;
      color: rgba(45, 49, 56, 0.7);
      font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: 500; }
    .building-graph .building-side-options .building-side-button:hover,
    .building-graph .building-side-options .building-side-button.active,
    .building-graph .building-side-options .building-side-button[aria-pressed="true"] {
      border: solid 2px rgba(45, 49, 56, 0.7);
      background-color: white;
      color: #3C414B;
      font-weight: 600; }
      .building-graph .building-side-options .building-side-button:hover.not-ready,
      .building-graph .building-side-options .building-side-button.active.not-ready,
      .building-graph .building-side-options .building-side-button[aria-pressed="true"].not-ready {
        background-color: white;
        border: solid 1px #EEEDEE;
        font-weight: normal;
        cursor: not-allowed; }
    .building-graph .building-side-options .building-side-oat input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer; }
    .building-graph .building-side-options .building-side-oat .building-side-oat-label:before {
      content: '';
      padding: 0;
      display: inline-block;
      height: 16px;
      width: 16px;
      border: solid 1px #EEEDEE;
      border-radius: 0;
      font-size: 12px;
      vertical-align: middle; }
    .building-graph .building-side-options .building-side-oat input + .building-side-oat-label:before {
      color: white;
      background-color: white;
      margin-right: 7px;
      border-radius: 3px; }
    .building-graph .building-side-options .building-side-oat input:checked + .building-side-oat-label:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-side-options .building-side-oat input + .building-side-oat-label {
      font-size: 15px;
      color: rgba(60, 65, 75, 0.5); }
    .building-graph .building-side-options .building-side-oat input:checked + .building-side-oat-label {
      color: black; }
  .building-graph .building-commodities-wrap .building-commodities {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    padding: 0;
    margin-left: 15px; }
    .building-graph .building-commodities-wrap .building-commodities .building-side-oat-label-spacer {
      padding-left: 31%; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      width: 150px;
      margin-top: 12px; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity {
      text-align: left;
      position: relative;
      height: 21px;
      width: 100%;
      line-height: 21px;
      display: block;
      cursor: pointer; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input {
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity .building-commodity-label:before {
      content: '';
      padding: 0;
      display: inline-block;
      height: 16px;
      width: 16px;
      border: solid 1px #EEEDEE;
      font-size: 12px;
      vertical-align: middle; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input + .building-commodity-label:before {
      color: white;
      background-color: white;
      margin-right: 7px;
      border-radius: 3px; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.electricity:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.gas:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%238D67D6' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.steam:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23f69222' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.hotWater:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23FFC149' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.chilledWater:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%231caac8' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.solar:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F8D81C' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label.oat:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input + .building-commodity-label {
      font-size: 15px;
      color: rgba(60, 65, 75, 0.5); }
    .building-graph .building-commodities-wrap .building-commodities .building-commodity input:checked + .building-commodity-label {
      color: black; }
  @media (min-width: 851px) {
    .building-graph .building-chart {
      width: 72%; }
    .building-graph .building-side-options {
      width: 27%; }
    .building-graph .building-commodities {
      width: 70%;
      padding: 10px 0 0 2%; }
      .building-graph .building-commodities .building-commodity input + .building-commodity-label:before {
        margin-right: 4px; } }
  @media (min-width: 1080px) {
    .building-graph .building-chart {
      width: 80%; }
    .building-graph .building-side-options {
      width: 19%; }
    .building-graph .building-commodities {
      width: 62%;
      padding: 10px 5% 0 12%; }
      .building-graph .building-commodities .building-commodity input + .building-commodity-label:before {
        margin-right: 15px; } }
  .building-graph .csv {
    padding-top: 5px;
    width: 100%;
    max-width: 150px;
    margin-top: 65px; }
    .building-graph .csv .building-side-button {
      display: flex;
      font-size: 14px;
      color: rgba(45, 49, 56, 0.7); }
      .building-graph .csv .building-side-button img {
        width: 11px;
        height: 13px;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 2px; }
    .building-graph .csv a {
      color: #3C414B;
      text-decoration: none; }
  .building-graph .csv-usage {
    padding-top: 5px;
    width: 100%;
    max-width: 150px;
    margin-top: 31px; }
  .building-graph .highcharts-root {
    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .building-graph .highcharts-root .highcharts-axis-labels {
      letter-spacing: 1px;
      text-transform: uppercase; }
