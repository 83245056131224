.building-comparison-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  color: black; }

.building-comparison-options {
  display: flex;
  justify-content: space-between;
  padding-top: 34px; }
  .building-comparison-options input {
    margin: 2px 12px 0 0; }
  .building-comparison-options .building-comparison-options-building-types {
    text-align: left;
    position: relative;
    height: 21px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer; }
  .building-comparison-options .building-comparison-options-building-types input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none; }
  .building-comparison-options .building-comparison-options-building-types .building-comparison-options-building-types-label:before {
    content: '';
    padding: 0px;
    display: inline-block;
    height: 16px;
    width: 16px;
    border: solid 1px #d6d6d6;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 4px;
    vertical-align: middle; }
  .building-comparison-options .building-comparison-options-building-types input + .building-comparison-options-building-types-label:before {
    color: white;
    background-color: white;
    margin-right: 4px; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label.lab:before {
    background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%232a575f' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label.office:before {
    background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2393bb45' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label.housing:before {
    background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%233778BC' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label.classroom:before {
    background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%233ea0b7' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label.community:before {
    background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23107d4b' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
  .building-comparison-options .building-comparison-options-building-types input + .building-comparison-options-building-types-label {
    font-size: 15px;
    cursor: pointer;
    color: rgba(60, 65, 75, 0.5);
    margin-right: 35px; }
  .building-comparison-options .building-comparison-options-building-types input:checked + .building-comparison-options-building-types-label {
    color: black; }
  .building-comparison-options .building-comparison-normalize-switch {
    position: relative;
    display: inline-block;
    top: 3px;
    width: 30px;
    height: 17px;
    margin-right: 5px;
    cursor: pointer; }
  .building-comparison-options .building-comparison-normalize-switch input {
    display: none; }
  .building-comparison-options .building-comparison-normalize-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d6d6d6;
    -webkit-transition: .4s;
    transition: .4s;
    border: solid 1px #d6d6d6;
    border-radius: 17px; }
  .building-comparison-options .building-comparison-normalize-slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: 1px;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%; }
  .building-comparison-options input:checked + .building-comparison-normalize-slider {
    background-color: white; }
  .building-comparison-options input:checked + .building-comparison-normalize-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px); }
  .building-comparison-options .building-comparison-options-normalize-label {
    display: inline-block;
    font-size: 15px;
    cursor: pointer; }
  .building-comparison-options .inactive {
    color: rgba(60, 65, 75, 0.5); }

@media (min-width: 769px) {
  .building-comparison-options {
    flex-direction: column;
    flex-wrap: wrap; }
    .building-comparison-options .building-comparison-options-normalize {
      padding-top: 20px;
      align-self: flex-end; } }

@media (min-width: 1080px) {
  .building-comparison-options {
    flex-direction: row; }
    .building-comparison-options .building-comparison-options-normalize {
      padding-top: 0px; } }

.building-comparison-grid-header {
  width: 106%;
  position: relative;
  left: -3%;
  background: #F6F6F6;
  border: 1px solid white;
  display: flex;
  color: #3C414B;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 32px; }
  .building-comparison-grid-header img {
    padding-right: 10px;
    vertical-align: middle; }
  .building-comparison-grid-header .building-comparison-grid-header-row {
    display: flex;
    border-right: 1px solid white;
    padding: 16px 1.5%;
    cursor: pointer; }
  .building-comparison-grid-header .building-comparison-grid-header-row:hover {
    color: black; }
    .building-comparison-grid-header .building-comparison-grid-header-row:hover img {
      filter: brightness(-200%); }
  .building-comparison-grid-header .building-comparison-grid-header-row.col1 {
    width: 18%; }
  .building-comparison-grid-header .building-comparison-grid-header-row.col2 {
    width: 25%; }
  .building-comparison-grid-header .building-comparison-grid-header-row.col3,
  .building-comparison-grid-header .building-comparison-grid-header-row.col4 {
    width: 14%; }
  .building-comparison-grid-header .building-comparison-grid-header-row.col5 {
    width: 15%;
    font-size: 14px; }
  .building-comparison-grid-header .building-comparison-grid-header-row.col6 {
    width: 14%;
    border-right: none; }
  .building-comparison-grid-header .grid-column-text {
    margin: auto 0; }

.building-comparison-main-building {
  font-weight: 600;
  width: 106%;
  position: relative;
  left: -3%; }
  .building-comparison-main-building.scrollable {
    width: 104%; }
  .building-comparison-main-building svg {
    border: 1px solid black;
    border-right: none; }
  .building-comparison-main-building .building-comparison-grid-row-svg-end {
    border-left: none;
    border-right: 1px solid black; }

.building-comparison-comparables {
  width: 106%;
  max-height: 410px;
  position: relative;
  left: -3%;
  overflow-x: visible;
  overflow-y: auto; }
  .building-comparison-comparables a {
    text-decoration: none; }
  .building-comparison-comparables .building-comparison-grid-row:hover {
    background-color: #f5f4f5; }

.building-comparison-grid-row {
  font-size: 15px;
  line-height: 18px;
  border-bottom: 1px solid #f5f4f5;
  display: flex;
  color: black;
  text-decoration: none; }
  .building-comparison-grid-row .building-comparison-grid-row-content {
    display: flex;
    width: 100%;
    padding: 10px 1.5%; }
    .building-comparison-grid-row .building-comparison-grid-row-content .building-comparison-grid-row-text {
      width: 25%; }
    .building-comparison-grid-row .building-comparison-grid-row-content .building-comparison-grid-row-svg {
      width: 75%; }
  .building-comparison-grid-row .col1,
  .building-comparison-grid-row .col2,
  .building-comparison-grid-row .col3,
  .building-comparison-grid-row .col4,
  .building-comparison-grid-row .col5,
  .building-comparison-grid-row .col6 {
    display: block; }
  .building-comparison-grid-row .col1 {
    width: 18%; }
  .building-comparison-grid-row .col2 {
    width: 25%;
    display: flex; }
  .building-comparison-grid-row .col3,
  .building-comparison-grid-row .col4 {
    width: 14%;
    text-align: center; }
  .building-comparison-grid-row .col3 {
    color: black; }
  .building-comparison-grid-row .col4 {
    color: black; }
  .building-comparison-grid-row .col5 {
    width: 15%;
    text-align: center;
    color: black; }
  .building-comparison-grid-row .col6 {
    width: 14%;
    border-right: none;
    text-align: right; }
