.mobile-metrics {
  margin: auto;
  padding-top: 30px;
  font-size: 16px;
  line-height: 20px; }
  .mobile-metrics .mobile-card {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 0px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    object-fit: contain; }
    .mobile-metrics .mobile-card .highcharts-container {
      overflow: visible !important; }
    .mobile-metrics .mobile-card .highcharts-container svg {
      overflow: visible !important; }
  .mobile-metrics .mobile-campus-metrics {
    height: 700px; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-headline {
      text-align: center;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 40px;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      padding: 20px 0 0px; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-description {
      width: 80%;
      margin: 0 auto;
      padding-bottom: 30px; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-view-selection-options {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      margin: auto;
      margin-top: 0px;
      justify-content: center;
      width: 95%;
      height: 200px; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-view-selection-options span {
        padding: 22px;
        width: 95%;
        text-align: center;
        margin: auto;
        font-weight: 600; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-view-selection-options .mobile-metrics-view-button {
        border: 2px solid #EEEDEE;
        border-radius: 3px;
        width: 120px;
        text-align: center;
        font-size: 15px;
        background-color: white;
        color: rgba(60, 65, 75, 0.7);
        padding: 10px;
        margin: auto;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all ease 0.2s; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-view-selection-options .mobile-metrics-view-button:hover, .mobile-metrics .mobile-campus-metrics .mobile-metrics-view-selection-options .mobile-metrics-view-button.active {
          border: 2px solid #2D3138;
          background-color: white;
          color: #3C414B;
          font-weight: 600; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection {
      padding-top: 16px; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection span {
        font-weight: 600; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection .mobile-metrics-year-selection-options {
        display: flex;
        flex-wrap: wrap; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection .mobile-metrics-year-selection-options .mobile-metrics-year-button {
          padding: 10px 3px 8px;
          color: rgba(60, 65, 75, 0.7);
          width: 7%;
          min-width: 62px;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          transition: all ease 0.2s;
          margin: 2px 1px; }
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection .mobile-metrics-year-selection-options .mobile-metrics-year-button:hover, .mobile-metrics .mobile-campus-metrics .mobile-metrics-year-selection .mobile-metrics-year-selection-options .mobile-metrics-year-button.active {
            width: 7%;
            background-color: white;
            color: #3C414B;
            font-weight: 600; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-line {
      padding-top: 0px;
      font-weight: 600;
      width: 100%; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-graph {
        width: 100%;
        padding-top: 10px; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-graph .highcharts-container {
          overflow: visible !important; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-graph .highcharts-container svg {
          overflow: visible !important; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data {
        display: flex;
        flex-direction: column;
        margin: auto;
        font-size: 14px;
        line-height: 22px;
        font-weight: normal; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy,
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage {
          display: flex;
          width: 40%;
          min-width: 300px;
          margin: auto; }
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy span,
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage span {
            width: 60%; }
            .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy span svg,
            .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage span svg {
              margin-right: 8px;
              vertical-align: text-top; }
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy .mobile-line-data-energy-amount,
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy .mobile-line-data-footage-amount,
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage .mobile-line-data-energy-amount,
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage .mobile-line-data-footage-amount {
            width: 24%;
            text-align: right;
            color: #99be4f;
            font-size: 15px;
            font-weight: 600; }
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-energy .mobile-line-data-footage-amount,
          .mobile-metrics .mobile-campus-metrics .mobile-metrics-line .mobile-metrics-line-data .mobile-line-data-footage .mobile-line-data-footage-amount {
            color: #2D3138; }
    .mobile-metrics .mobile-campus-metrics .mobile-metrics-bar {
      padding-top: 40px;
      font-weight: 600; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-bar .mobile-metrics-bar-data {
        position: relative;
        top: -23px;
        left: 40%;
        font-weight: normal;
        font-size: 14px; }
        .mobile-metrics .mobile-campus-metrics .mobile-metrics-bar .mobile-metrics-bar-data span {
          padding-right: 12px; }
      .mobile-metrics .mobile-campus-metrics .mobile-metrics-bar .mobile-metrics-bar-graph {
        width: 83%;
        margin-left: 6.8%; }
