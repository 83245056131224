.mobile-waste {
  display: flex;
  flex-direction: column;
  margin: 40px 0 20px;
  padding: 30px 10% 20px !important; }
  .mobile-waste .mobile-waste-icon {
    margin: auto; }
  .mobile-waste .mobile-waste-title {
    margin: 10px auto;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #212832; }
  .mobile-waste .mobile-waste-detail {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    color: black; }
  .mobile-waste .mobile-waste-how-to-button {
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 0 -14%;
    padding: 22px 14% 18px;
    border: 1px solid #F5F5F5;
    font-size: 15px;
    line-height: 18px;
    color: #97C13D;
    font-weight: 600; }
    .mobile-waste .mobile-waste-how-to-button .mobile-waste-toggle-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      margin-top: -2px; }
  .mobile-waste .mobile-waste-how-to {
    max-height: 0;
    padding: 0 14%;
    overflow: hidden;
    margin: 0 -14%;
    background: #F5F5F5;
    font-size: 15px;
    line-height: 18px;
    transition: ease all 0.4s; }
    .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-detail {
      margin-bottom: 15px; }
    .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-images {
      display: flex;
      justify-content: space-around;
      margin-top: 15px; }
      .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-images .mobile-waste-how-to-image {
        display: inline-block; }
        .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-images .mobile-waste-how-to-image img {
          width: 70%;
          max-width: 120px; }
    .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-link {
      margin-top: 30px;
      text-decoration: none;
      color: black; }
      .mobile-waste .mobile-waste-how-to .mobile-waste-how-to-link b {
        text-decoration: underline; }
    .mobile-waste .mobile-waste-how-to.display {
      transition: ease all 0.4s;
      max-height: none;
      padding: 30px 14%; }
  .mobile-waste .mobile-waste-survey {
    margin: 30px auto 20px;
    font-size: 15px;
    line-height: 18px;
    color: #212832; }
    .mobile-waste .mobile-waste-survey .mobile-waste-survey-headline {
      color: black;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      padding: 15px 0; }
    .mobile-waste .mobile-waste-survey .mobile-waste-options {
      height: 300px;
      margin: 30px auto 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option {
        display: flex;
        opacity: 0.5;
        transition: ease all 0.3s; }
        .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option .mobile-waste-option-dot {
          box-sizing: border-box;
          margin: 2px 10px 0 0;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: #F5F5F5;
          transition: ease all 0.3s; }
          .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option .mobile-waste-option-dot .mobile-waste-option-dot-inner {
            display: none;
            height: 10px;
            width: 10px;
            border-radius: 5px;
            border-radius: 5px;
            margin: 4px auto;
            background-color: black;
            transition: ease all 0.3s; }
        .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option img {
          width: 38px;
          height: 25px;
          vertical-align: middle;
          margin-right: 10px;
          filter: grayscale(100%); }
        .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option .mobile-waste-option-text {
          width: 70%; }
        .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.selected, .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.all {
          opacity: 1; }
          .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.selected .mobile-waste-option-dot, .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.all .mobile-waste-option-dot {
            border: 1px solid #000000; }
          .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.selected img, .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.all img {
            filter: none; }
        .mobile-waste .mobile-waste-survey .mobile-waste-options .mobile-waste-option.selected .mobile-waste-option-dot .mobile-waste-option-dot-inner {
          display: block; }
    .mobile-waste .mobile-waste-survey .mobile-waste-question {
      display: flex;
      flex-direction: column;
      margin: 40px 4%;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600; }
      .mobile-waste .mobile-waste-survey .mobile-waste-question input {
        height: 19px;
        padding: 11px 15px 10px;
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
        border: 1px solid #D3D3D3; }
    .mobile-waste .mobile-waste-survey .mobile-waste-button {
      width: 225px;
      height: 19px;
      padding: 10px 15px 11px;
      margin: 50px auto 20px;
      background: #CECECE;
      text-align: center;
      font-weight: 600;
      color: white;
      transition: ease all 0.3s; }
      .mobile-waste .mobile-waste-survey .mobile-waste-button.active {
        background: #212832;
        color: white; }
      .mobile-waste .mobile-waste-survey .mobile-waste-button.posted {
        color: #97C13D;
        background: transparent; }
    .mobile-waste .mobile-waste-survey .mobile-waste-confirmation {
      height: 18px;
      margin-top: -20px;
      color: #97C13D;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      font-style: italic; }
