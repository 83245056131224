.ewc-savings-card-desktop {
  display: flex;
  justify-content: space-between;
  height: 290px;
  margin-top: 30px; }
  .ewc-savings-card-desktop .ewc-savings-card-left {
    width: 65%;
    margin-right: 30px; }
    .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-card-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600; }
    .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-chart {
      width: 430px;
      height: 220px;
      margin: 15px auto; }
    .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-range-picker {
      display: flex;
      justify-content: space-between;
      width: 80%;
      max-width: 250px;
      margin: auto;
      font-size: 15px;
      line-height: 18px;
      font-weight: 600; }
      .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-range-picker .ewc-savings-range-arrow {
        padding: 2px;
        cursor: pointer; }
        .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-range-picker .ewc-savings-range-arrow.hidden {
          visibility: hidden; }
        .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-range-picker .ewc-savings-range-arrow.right img {
          margin-top: -1px;
          transform: rotate(180deg); }
      .ewc-savings-card-desktop .ewc-savings-card-left .ewc-savings-range-picker .ewc-savings-range-selection {
        margin-top: 5px; }
  .ewc-savings-card-desktop .ewc-savings-card-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 35%;
    color: #9B9B9B;
    font-size: 14px;
    line-height: 24px; }
    .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats {
      display: flex;
      flex-direction: column;
      margin: 0 12%; }
      .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats .ewc-savings-detail {
        display: flex;
        justify-content: space-between; }
        .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value {
          color: black;
          font-size: 15px; }
          .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.electricity {
            color: #92BD3B; }
          .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.water {
            color: #32BBD5; }
          .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-stats .ewc-savings-detail .ewc-savings-detail-value.overuse {
            color: #FC6616; }
    .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-example {
      display: flex; }
      .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-example img {
        width: 5%;
        height: auto;
        margin-right: 7%; }
      .ewc-savings-card-desktop .ewc-savings-card-right .ewc-savings-example .ewc-savings-example-detail {
        width: 85%; }
