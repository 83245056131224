.mobile-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  background-color: transparent;
  text-align: center;
  font-weight: 600;
  z-index: 100; }

.mobile-search-input {
  position: relative; }
  .mobile-search-input img {
    position: absolute;
    top: 2px;
    left: 4px;
    padding: 4px;
    background: white;
    z-index: 100; }

input.mobile-search-input-control {
  width: 90%;
  width: -webkit-fill-available;
  padding: 5px 20px;
  background: white;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  color: #212832;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.mobile-search-results {
  max-height: 250px;
  margin-top: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  background: white; }

.mobile-search-row {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 50px;
  font-weight: 600;
  text-align: left;
  color: #212832; }
  .mobile-search-row .mobile-search-row-building {
    width: 100%;
    margin: 0 20px;
    background: white; }
  .mobile-search-row .no-results {
    color: #676767; }
  .mobile-search-row .no-results:hover {
    color: black; }
  .mobile-search-row .no-results {
    cursor: auto; }

.mobile-search-row:hover {
  background-color: #efefef; }
