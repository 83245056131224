.apps-page {
  width: 400px;
  height: 100vh;
  background: white;
  flex-shrink: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 50; }

.apps-page.hide {
  left: -410px;
  z-index: 100; }

.apps-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; }
  .apps-content .apps-menu {
    width: 100%;
    color: #3C414B;
    font-size: 16px;
    font-weight: 600; }
    .apps-content .apps-menu .apps-menu-current,
    .apps-content .apps-menu .apps-menu-item {
      height: 80px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      transition: ease all 0.3s; }
      .apps-content .apps-menu .apps-menu-current img,
      .apps-content .apps-menu .apps-menu-item img {
        width: 40px;
        height: 40px;
        margin-right: 30px;
        filter: grayscale(100%) brightness(90%); }
    .apps-content .apps-menu .apps-menu-current {
      background-color: #f7f7f7;
      cursor: default; }
    .apps-content .apps-menu .apps-menu-item:hover {
      color: #92BD3B; }
      .apps-content .apps-menu .apps-menu-item:hover img {
        filter: none; }
  .apps-content a {
    text-decoration: none;
    color: inherit; }
  .apps-content .apps-menu-email {
    margin: 20px auto 60px; }
