.total-campus-graph-title {
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 0; }
  .total-campus-graph-title .demand-zoom-helper {
    position: absolute;
    right: 30%;
    bottom: 0;
    color: #3c414b;
    opacity: 0.9;
    font-size: 13px;
    font-weight: normal; }
    .total-campus-graph-title .demand-zoom-helper img {
      margin-bottom: -1px; }
    @media (min-width: 1080px) {
      .total-campus-graph-title .demand-zoom-helper {
        right: 21%; } }

.chart-wrapper {
  display: flex;
  flex-direction: row; }
  .chart-wrapper .graph-and-checkboxes,
  .chart-wrapper .buttons {
    display: flex;
    flex-direction: column; }
  .chart-wrapper .graph-and-checkboxes {
    width: 85%;
    text-align: center; }

.campus-demand {
  padding: 40px 12%;
  max-width: 900px;
  margin: auto; }
  .campus-demand .campus-demand-story {
    width: 69%;
    margin-left: 10%;
    font-size: 16px;
    line-height: 20px; }

.super-graph-card {
  background-color: white;
  height: fit-content;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 3% 40px 3%; }
  .super-graph-card .total-energy-story {
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: row; }
    .super-graph-card .total-energy-story .total-energy-story-column1 {
      width: 50%;
      flex-direction: column; }
      .super-graph-card .total-energy-story .total-energy-story-column1 .total-energy-story-box {
        display: flex;
        flex-direction: row; }
    .super-graph-card .total-energy-story .total-energy-story-column2 {
      width: 50%;
      flex-direction: column; }
      .super-graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box {
        display: flex;
        flex-direction: row; }

.units-label {
  color: #6c6c6c;
  font-size: 13px;
  margin-bottom: 50px; }

.campus-graph {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .campus-graph .highcharts-container {
    overflow: visible !important; }
  .campus-graph .highcharts-container svg {
    overflow: visible !important; }
  .campus-graph .campus-side-options {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    color: #b2b2b2;
    padding-top: 5px; }
    .campus-graph .campus-side-options .campus-side-button {
      width: 100%;
      height: 40px;
      border: solid 1px #dbdbdb;
      box-sizing: border-box;
      margin: 5px;
      padding: 12px 0;
      cursor: pointer;
      background: white;
      color: #b2b2b2;
      font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      letter-spacing: 0.6px; }
    .campus-graph .campus-side-options .campus-side-button:hover,
    .campus-graph .campus-side-options .campus-side-button.active,
    .campus-graph .campus-side-options .campus-side-button[aria-pressed="true"] {
      background-color: #3c414b;
      border: solid 1px #3c414b;
      color: white;
      font-weight: 600; }
    .campus-graph .campus-side-options .campus-side-oat-wrapper {
      padding-left: 12px;
      padding-top: 24px; }
      .campus-graph .campus-side-options .campus-side-oat-wrapper .campus-side-oat-label-spacer {
        padding-left: 34px; }
    .campus-graph .campus-side-options .campus-side-oat {
      text-align: left;
      position: relative;
      height: 21px;
      line-height: 21px;
      display: block;
      cursor: pointer;
      margin-top: 20px; }
    .campus-graph .campus-side-options .campus-side-oat input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer; }
    .campus-graph .campus-side-options .campus-side-oat .campus-side-oat-label:before {
      content: '';
      padding: 0;
      display: inline-block;
      height: 16px;
      width: 16px;
      border: solid 1px #d6d6d6;
      border-radius: 0;
      font-size: 12px;
      margin-right: 7px;
      vertical-align: middle; }
    .campus-graph .campus-side-options .campus-side-oat input + .campus-side-oat-label:before {
      color: white;
      background-color: white;
      margin-right: 15px; }
  .campus-graph .campus-commodities {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .campus-graph .campus-commodities .campus-commodity {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      margin: auto;
      margin-bottom: 10px;
      min-width: 180px;
      text-align: left;
      position: relative;
      height: 21px;
      line-height: 21px;
      display: block;
      cursor: pointer; }
      .campus-graph .campus-commodities .campus-commodity input {
        margin: 2px 12px 0 0; }
    .campus-graph .campus-commodities .campus-commodity input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer; }
    .campus-graph .campus-commodities .campus-commodity .campus-commodity-label:before {
      content: '';
      padding: 0;
      display: inline-block;
      height: 16px;
      width: 16px;
      border: solid 1px #d6d6d6;
      border-radius: 0;
      font-size: 12px;
      margin-right: 7px;
      vertical-align: middle; }
    .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label:before {
      color: white;
      background-color: white; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.pge_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2399c23f' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.solar_farm_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23FFDB7E' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.chcp_gas_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23233352' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.rooftop_solar_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F2C75A' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.biodigester_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%239AC049' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label.other_gas_demand:before {
      background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center; }
    .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label {
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      color: #a6a6a6; }
    .campus-graph .campus-commodities .campus-commodity input:checked + .campus-commodity-label {
      color: black; }
    .campus-graph .campus-commodities .total-energy-story {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: auto;
      font-size: 14px; }
      .campus-graph .campus-commodities .total-energy-story img {
        height: 40px;
        margin-right: 25px; }
      .campus-graph .campus-commodities .total-energy-story div {
        display: inline-block;
        font-weight: 450; }
        .campus-graph .campus-commodities .total-energy-story div .renewable,
        .campus-graph .campus-commodities .total-energy-story div .carbon-neutral {
          position: absolute;
          font-weight: bold;
          width: 110px;
          color: #98BF47;
          background-color: #f4f7ed;
          text-align: center;
          border-radius: 10px;
          margin-left: 15px; }
        .campus-graph .campus-commodities .total-energy-story div .carbon-neutral {
          width: 140px; }
        .campus-graph .campus-commodities .total-energy-story div p {
          font-weight: 10;
          color: #818388;
          margin-bottom: 25px;
          margin-top: 8px; }
        .campus-graph .campus-commodities .total-energy-story div span {
          font-style: italic;
          font-weight: 10;
          color: #818388; }
      .campus-graph .campus-commodities .total-energy-story .total-energy-story-column1 {
        width: 40%;
        flex-direction: column; }
        .campus-graph .campus-commodities .total-energy-story .total-energy-story-column1 .total-energy-story-box {
          display: flex;
          flex-direction: row; }
      .campus-graph .campus-commodities .total-energy-story .total-energy-story-column2 {
        width: 40%;
        flex-direction: column; }
        .campus-graph .campus-commodities .total-energy-story .total-energy-story-column2 .total-energy-story-box {
          display: flex;
          flex-direction: row; }
  @media (max-width: 768px) {
    .campus-graph .total-energy-campus-chart {
      width: 100%; } }
  @media (min-width: 769px) {
    .campus-graph .total-energy-campus-chart {
      width: 72%; }
    .campus-graph .campus-side-options {
      width: 27%; }
    .campus-graph .campus-commodities {
      width: 70%;
      padding: 10px;
      margin-left: 9%; }
      .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label:before {
        margin-right: 4px; } }
  @media (min-width: 1080px) {
    .campus-graph .total-energy-campus-chart {
      width: 80%; }
    .campus-graph .campus-side-options {
      width: 19%; }
    .campus-graph .campus-commodities {
      width: 70%;
      padding: 10px; }
      .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label:before {
        margin-right: 15px; } }
  .campus-graph .csv {
    padding-top: 5px; }
    .campus-graph .csv a {
      color: #b2b2b2;
      text-decoration: none; }
  .campus-graph .highcharts-root {
    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .campus-graph .highcharts-root .highcharts-axis-labels {
      letter-spacing: 1px;
      text-transform: uppercase; }
