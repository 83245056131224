.buttons-wrapper {
  padding: 0px 7px;
  display: flex;
  flex-wrap: wrap; }
  .buttons-wrapper button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: white;
    height: 35px;
    -webkit-padding-start: 0px;
    padding-top: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    margin: 4px;
    display: flex;
    opacity: 0.9;
    transition: ease all 0.3s; }
    .buttons-wrapper button.lab {
      background-color: #285760; }
    .buttons-wrapper button.office {
      background-color: #92bd3b; }
    .buttons-wrapper button.housing {
      background-color: #3778bc; }
    .buttons-wrapper button.classroom {
      background-color: #3EA0B7; }
    .buttons-wrapper button.community {
      background-color: #007d47; }
    .buttons-wrapper button:hover {
      transform: scale(1.08);
      transition: transform 0.1s; }
    .buttons-wrapper button:focus {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.48); }
    .buttons-wrapper button span.icon {
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 7%;
      transform: rotate(45deg);
      transform-origin: center center;
      transition: transform 0.3s;
      width: 16px;
      height: 7px;
      filter: brightness(300%); }
    .buttons-wrapper button span.text {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 1px;
      margin-left: 5px; }
    .buttons-wrapper button span {
      padding-bottom: 7%; }
    .buttons-wrapper button .building-type-icon {
      position: relative;
      top: -9px; }
    .buttons-wrapper button[aria-pressed="false"] {
      background: transparent;
      box-shadow: unset;
      border: 0;
      color: #a5a5a5;
      transition: ease all 0.3s; }
      .buttons-wrapper button[aria-pressed="false"] span.icon {
        filter: none;
        transform: rotate(-90deg);
        transform-origin: center center;
        transition: transform 0.3s; }
      .buttons-wrapper button[aria-pressed="false"] .building-type-icon {
        filter: invert(0.3); }
  @media (max-height: 700px) {
    .buttons-wrapper {
      padding: 4px 0; }
      .buttons-wrapper button {
        padding-left: 2px;
        padding-right: 4px;
        margin: 3px 4px; }
        .buttons-wrapper button span.icon {
          margin-right: 0; }
        .buttons-wrapper button span.text {
          font-size: 12px;
          margin-bottom: -2px; } }
