.campus-footer {
  width: 90%;
  max-width: 900px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between; }
  .campus-footer .footer-content {
    width: 30%;
    max-width: 220px;
    color: #3C414B;
    font-size: 15px;
    line-height: 27px; }
    .campus-footer .footer-content .footer-title {
      font-weight: 600;
      margin-bottom: 10px; }
    .campus-footer .footer-content .footer-link {
      opacity: 0.8;
      letter-spacing: 0.16px; }
      .campus-footer .footer-content .footer-link Link,
      .campus-footer .footer-content .footer-link a {
        text-decoration: none;
        color: #3C414B; }
    .campus-footer .footer-content .footer-logo {
      margin-top: 4px; }
      .campus-footer .footer-content .footer-logo img {
        width: 75%; }
    .campus-footer .footer-content .footer-description {
      line-height: 18px;
      padding-top: 10px; }
      .campus-footer .footer-content .footer-description.contact-us {
        padding-top: 20px; }
        .campus-footer .footer-content .footer-description.contact-us a {
          color: #3C414B; }
