.building-demand {
  background-color: #eeedee;
  margin-top: 0; }

.building-demand-title {
  font-size: 16px;
  text-align: left;
  padding-bottom: 25px;
  padding-top: 20px; }

.wrap {
  width: 100%;
  background-color: rgba(238, 237, 238, 0.5);
  margin: -30px -3% 20px -3%;
  padding: 40px 30px 20px 30px;
  border-bottom: 2px solid #eeedee;
  color: #3C414B; }
  .wrap .step1 .savings-calculator-title {
    padding-top: 30px;
    font-size: 17px;
    line-height: 22px;
    font-weight: 600; }
  .wrap .step1 .savings-calculator-description {
    padding-top: 30px;
    width: 95%;
    line-height: 22px; }
  .wrap .feature-wrap {
    display: flex;
    justify-content: space-around;
    width: 80%;
    align-items: center;
    margin: 0 auto; }
    .wrap .feature-wrap .select {
      width: 40%;
      font-weight: 600; }
      .wrap .feature-wrap .select .select-wrap {
        position: relative;
        width: 80%;
        margin: 30px auto 60px; }
        .wrap .feature-wrap .select .select-wrap .select-text {
          padding: 10px 0; }
    .wrap .feature-wrap .sliders {
      display: flex;
      justify-content: space-around;
      width: 40%;
      font-weight: 500;
      padding-left: 50px; }
      .wrap .feature-wrap .sliders .slider {
        display: flex;
        flex-direction: column;
        width: 93%; }
        .wrap .feature-wrap .sliders .slider .rheostat {
          margin-top: 40px; }
          .wrap .feature-wrap .sliders .slider .rheostat button {
            top: -8px;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            border: 3px solid #98BF47;
            background-color: white;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
            cursor: pointer; }
          .wrap .feature-wrap .sliders .slider .rheostat .rheostat-background {
            background-color: #98BF47;
            border: none;
            border-radius: 4px;
            height: 4px;
            margin-right: -20px; }
          .wrap .feature-wrap .sliders .slider .rheostat .rheostat-progress {
            background-color: #D8D8D8;
            border-radius: 4px;
            height: 4px;
            top: 0px; }
        .wrap .feature-wrap .sliders .slider .slider-values {
          padding-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }

.savings-calculator-graph {
  display: flex;
  flex-wrap: nowrap; }
  .savings-calculator-graph .legend {
    width: 20%;
    margin-left: 30px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .savings-calculator-graph .legend .potential-savings {
      font-weight: 500; }
    .savings-calculator-graph .legend .percent {
      width: 80px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      line-height: 42px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 25px;
      background-color: white;
      border: 3px solid #eeedee;
      margin: 10px 0 10px; }
    .savings-calculator-graph .legend .demand {
      width: 100%;
      padding: 20px 0 0;
      display: flex;
      flex-direction: column;
      font-weight: 500; }
      .savings-calculator-graph .legend .demand.current {
        flex-direction: row; }
      .savings-calculator-graph .legend .demand .demand-potential-wrap {
        display: flex; }
        .savings-calculator-graph .legend .demand .demand-potential-wrap .checkbox {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-top: -1px; }
          .savings-calculator-graph .legend .demand .demand-potential-wrap .checkbox.current, .savings-calculator-graph .legend .demand .demand-potential-wrap .checkbox.potential {
            width: 12px;
            height: 12px;
            background-color: #3C414B;
            border-radius: 50%;
            border: 2px solid #eeedee; }
          .savings-calculator-graph .legend .demand .demand-potential-wrap .checkbox.potential {
            background-color: #98BF47; }
        .savings-calculator-graph .legend .demand .demand-potential-wrap .potential-info-icon {
          margin-left: 5px;
          margin-bottom: 5px;
          cursor: pointer; }
      .savings-calculator-graph .legend .demand .potential-speech-bubble-hover {
        width: 13px;
        height: 13px;
        background-color: transparent;
        margin-top: -10%;
        margin-left: 85%;
        cursor: pointer; }
      .savings-calculator-graph .legend .demand .potential-speech-bubble-wrap {
        display: none; }
        .savings-calculator-graph .legend .demand .potential-speech-bubble-wrap .potential-speech-bubble {
          background: #F6F6F6;
          border: 3px solid #eeedee;
          -webkit-border-radius: 3px;
          border-radius: 3px;
          -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          font-size: 14px;
          line-height: 17px;
          color: #3C414B;
          padding: 0 15px;
          z-index: 1; }
        .savings-calculator-graph .legend .demand .potential-speech-bubble-wrap .potential-speech-bubble-arrow {
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #F6F6F6;
          margin-left: 82%; }
      .savings-calculator-graph .legend .demand .potential-speech-bubble-hover:hover + .potential-speech-bubble-wrap {
        display: inline; }
  .savings-calculator-graph .savings-calculator-chart {
    flex-grow: 1;
    width: -webkit-fill-available; }
