.sidebar {
  width: 400px;
  height: 100vh;
  min-height: 500px;
  flex-shrink: 0;
  color: #2D3138;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: flex;
  flex-direction: column; }
  @media (max-height: 800px) {
    .sidebar {
      overflow-y: scroll; } }
  .sidebar .sidebar-title {
    color: #2D3138;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 0 35px;
    margin-bottom: -5px; }
  .sidebar p,
  .sidebar .sidebar-view,
  .sidebar .sidebar-info {
    font-size: 14px;
    color: #2D3138;
    line-height: 18px;
    padding: 0px 30px 5px 35px; }
  .sidebar .sidebar-view {
    opacity: 0.7; }
  .sidebar .sidebar-green-button {
    width: 201px;
    height: 35px;
    border: 3px solid #EEEDEE;
    border-radius: 4px;
    color: rgba(45, 49, 56, 0.7);
    font-size: 15px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    margin-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: ease 0.2s all; }
    .sidebar .sidebar-green-button:hover, .sidebar .sidebar-green-button.active {
      color: #3C414B;
      background-color: white;
      border-color: #3C414B;
      font-weight: bold;
      border-width: 2px; }
  .sidebar .sidebar-info {
    opacity: 0.7;
    margin: 20px 0; }
    .sidebar .sidebar-info .sidebar-description {
      padding-bottom: 10px; }
    .sidebar .sidebar-info .sidebar-equation {
      display: flex; }
      .sidebar .sidebar-info .sidebar-equation .sidebar-eui {
        width: 85px; }
      .sidebar .sidebar-info .sidebar-equation .sidebar-equal {
        padding-top: 10px;
        padding-right: 20px; }
      .sidebar .sidebar-info .sidebar-equation .sidebar-eui-equation {
        display: flex;
        flex-direction: column; }
        .sidebar .sidebar-info .sidebar-equation .sidebar-eui-equation .sidebar-eq-top {
          border-bottom: 2px solid #C5C5C5; }
        .sidebar .sidebar-info .sidebar-equation .sidebar-eui-equation .sidebar-unit {
          font-weight: 500; }
  .sidebar .eui-key {
    margin-top: 20px; }
  .sidebar a {
    font-weight: 700;
    color: #2D3138; }
    .sidebar a:hover {
      color: black; }
  @media (max-height: 700px) {
    .sidebar p {
      margin: 6px 0; } }

.map-options {
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.29);
  padding: 14px 12px;
  margin: 8px 0; }
  @media (max-height: 700px) {
    .map-options {
      padding: 6px 12px; } }

.filter-slider-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 13px; }
  .filter-slider-wrapper .filter-slider-title {
    font-size: 14px; }
  .filter-slider-wrapper .filter-slider {
    display: flex;
    flex-direction: column;
    width: 230px;
    padding-right: 20px; }
    .filter-slider-wrapper .filter-slider .slider-values {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px; }
      .filter-slider-wrapper .filter-slider .slider-values span {
        font-size: 12px;
        text-align: center; }
        .filter-slider-wrapper .filter-slider .slider-values span:nth-of-type(2) {
          margin-right: -20px; }
    .filter-slider-wrapper .filter-slider .rheostat {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%; }
      .filter-slider-wrapper .filter-slider .rheostat button {
        top: -8px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 1px solid #bebebe;
        background-color: #ffffff;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer; }
      .filter-slider-wrapper .filter-slider .rheostat .rheostat-background {
        background-color: #D8D8D8;
        border: none;
        border-radius: 4px;
        height: 4px;
        margin-right: -20px; }
      .filter-slider-wrapper .filter-slider .rheostat .rheostat-progress {
        background-color: #285760;
        border-radius: 4px;
        height: 4px;
        top: 0;
        margin-right: -20px; }
